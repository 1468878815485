import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import InfiniteScroll from "react-infinite-scroller";
import { getGalleryPosts } from "../../actions/galleryActions";
import GalleryPost from "./GalleryPost";

const Gallery = () => {
  const dispatch = useDispatch();
  const galleryPage = useSelector((state) => state.galleryPage);

  useEffect(() => {
    document.title = "YourStone.ie - Gallery";
  }, []);

  useEffect(() => {
    dispatch(getGalleryPosts());
  }, [dispatch]);

  return (
    <GalleryContainer>
      {galleryPage && (
        <StyledInfiniteScroll
          pageStart={1}
          loadMore={() => loadPage(galleryPage, dispatch)}
          hasMore={galleryPage && !galleryPage.last}
          children={renderGalleryCards({ galleryPage })}
        />
      )}
    </GalleryContainer>
  );
};

export default Gallery;

const loadPage = (galleryPage, dispatch) => {
  galleryPage && dispatch(getGalleryPosts(galleryPage.pagination.next || 1));
};

const renderGalleryCards = ({ galleryPage }) => {
  return (
    galleryPage &&
    galleryPage.posts.map((post) => <GalleryPost key={post.id} post={post} />)
  );
};

const GalleryContainer = styled.div`
  width: 80%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  @media screen and (max-width: 568px) {
    width: 95%;
  }
`;

const StyledInfiniteScroll = styled(InfiniteScroll)`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  @media screen and (max-width: 568px) {
    width: 95%;
  }
`;
