import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";


const NavMenu = () => {
  return (
    <NavLinks>
      <li>
        <NavLink to="/">Home</NavLink>
      </li>
      <li>
        <NavLink to="/gallery">Gallery</NavLink>
      </li>
    </NavLinks>
  );
};

export default NavMenu;

const NavLinks = styled.ul`
  height: 100%;
  padding: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  list-style: none;

  li {
    padding: 0 1rem;
    height: 100%;

    &:hover {
      background: rgba(0, 0, 0, 0.1);
    }
  }
`;

const NavLink = styled(Link)`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: inherit;
`;
