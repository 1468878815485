import { handleActions } from "redux-actions";
import { produce } from "immer";

const initialState = {
  galleryPage: {
    posts: [],
  },
};

export default handleActions(
  {
    GET_GALLERY_POSTS_SUCCESS: produce((state, action) => {
      const page = state.galleryPage ? state.galleryPage.posts : [];
      state.galleryPage = {
        posts: [...page, ...action.payload.data],
        pagination: action.payload.pagination,
        last: action.payload.last,
      };
    }),
  },
  initialState
);
