import { handleActions } from 'redux-actions';
import { produce } from 'immer';

const initialState = {
  homePage: {
    id: null,
    sections: {},
    title: '',
  }
};

export default handleActions({
  GET_HOME_SUCCESS: produce((state, action) => { 
    state.homePage = action.payload;
  })
}, initialState);

