import React from "react";
import styled from "styled-components";

const GalleryPost = ({ post }) => {
  return (
    <GalleryPostCard>
      <GalleryCardImage src={post.image_url} />
      <PostDetails>
        <Title>{post.title}</Title>
        <Description>{post.description}</Description>
      </PostDetails>
    </GalleryPostCard>
  );
};

export default GalleryPost;

const GalleryPostCard = styled.div`
  width: 23rem;
  height: 23rem;
  margin: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  @media screen and (max-width: 568px) {
    width: 90vw;
    height: 90vw;
    margin: 2rem 0.2rem;
  }
`;

const GalleryCardImage = styled.img`
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 0;
`;

const PostDetails = styled.div`
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  position: absolute;
  top: 0;
  left: 0;
  color: var(--color-secondary);
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: opacity 0.2s ease-in-out;

  &:hover {
    cursor: pointer;
    opacity: 1;
  }
`;

const Title = styled.span`
  width: 95%;
  text-align: center;
  font-style: italic;
`;

const Description = styled.p`
  width: 85%;
  height: 60%;
  display: flex;
  align-items: center;
  font-weight: 300;
`;
