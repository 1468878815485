import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const Hero = ({ section }) => {
  return (
    <HeroBackground url={section.image_url}>
      <HeroContent>
        <HeroTitle>{section.title}</HeroTitle>
        <HeroBody>{section.body}</HeroBody>
        <HeroButton to="/gallery">View our Work</HeroButton>
      </HeroContent>
    </HeroBackground>
  );
};

export default Hero;

const HeroButton = styled(Link)`
  background: var(--color-primary);
  color: var(--color-secondary);
  font-size: 1.4rem;
  padding: 1rem 2rem;
  text-decoration: none;
  border-radius: 3px;

  &:hover {
    background: var(--color-primary-dark);
  }
`;

const HeroBody = styled.p`
  margin-bottom: 2rem;
`;

const HeroTitle = styled.h1`
  font-size: 3rem;
  font-weight: 700;
  margin-bottom: 1rem;

  @media screen and (max-width: 600px) {
    font-size: 2rem;
    margin-bottom: 0.5rem;
  }
`;

const HeroContent = styled.div`
  width: 60%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 2;
  color: var(--color-secondary);
  font-size: 1.4rem;
  line-height: 1.5;

  @media screen and (max-width: 600px) {
    font-size: 1.2rem;
    line-height: 1.2;
  }

  @media screen and (max-width: 850px) {
    width: 90%;
  }
`;

const HeroBackground = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35rem;
  background: url(${(props) => props.url});
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;

  &:after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    background: rgba(0, 0, 0, 0.4);
  }
`;
