import React from "react";
import styled from "styled-components";

const StackingSplitView = ({ sections, title }) => {
  return (
    <StackingWrapper>
      {title && <StackingTitle>{title}</StackingTitle>}
      {renderSections(sections)}
    </StackingWrapper>
  );
};

const SplitViewSection = ({ section }) => {
  return (
    <SectionWrapper>
      <SectionTitle>{section.title}</SectionTitle>
      <SectionBody>{section.body}</SectionBody>
    </SectionWrapper>
  );
};

export default StackingSplitView;

const renderSections = sections => {
  return sections.map(section => (
    <SplitViewSection key={section.id} section={section} />
  ));
};

const StackingWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
`;

const StackingTitle = styled.h1`
  width: 100vw;
  text-align: center;
  font-size: 3rem;
  letter-spacing: 1.2;
`;

const SectionWrapper = styled.div`
  width: 50vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;
  height: 25rem;

  @media screen and (max-width: 768px) {
    width: 100vw;
    height: max-content;
  }
`;

const SectionTitle = styled.h2`
  font-size: 2.4rem;
  width: 100%;
  text-align: center;
`;

const SectionBody = styled.p`
  width: 70%;
  font-size: 1.4rem;
  line-height: 1.5;

  @media screen and (max-width: 768px) {
    width: 90%;
  }
`;
