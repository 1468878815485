import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import getHome from "../../actions/homeActions";
import { MdLocationOn, MdPhone, MdEmail } from "react-icons/md";
import Hero from "./Hero";
import StackingSplitView from "./StackingSplitView";

const Home = () => {
  const dispatch = useDispatch();
  const page = useSelector((state) => state.homePage);

  useEffect(() => {
    document.title = "YourStone.ie - Home";
  }, []);

  useEffect(() => {
    dispatch(getHome());
  }, [dispatch]);

  return (
    <PageWrapper>
      {renderHero({ page })}
      {renderSplitView({ page })}
      <ContactSection>
        <ContactInfo>
          <MdLocationOn /> Roo, Gort, Co. Galway, H91R6PP
        </ContactInfo>
        <ContactInfo>
          <MdPhone /> +353877406726
        </ContactInfo>
        <ContactInfo>
          <MdEmail /> brianharney@yahoo.com
        </ContactInfo>
      </ContactSection>
    </PageWrapper>
  );
};

export default Home;

const renderHero = ({ page }) => {
  return (
    page.sections.hero_section && <Hero section={page.sections.hero_section} />
  );
};

const renderSplitView = ({ page }) => {
  return (
    page.sections.architectural &&
    page.sections.memorial && (
      <StackingSplitView
        title="What We Offer"
        sections={[page.sections.architectural, page.sections.memorial]}
      />
    )
  );
};

const openMailClient = () => {
  const subject = "YourStone.ie - Consultation Request";
  const body =
    "Hello Brian,%0A%0AI'm getting in contact to arrange a consultation about your services.";
  const link = `mailto:cian.guinee@gmail.com?subject=${subject}&body=${body}`;
  window.open(link, "_blank");
};

const PageWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  color: var(--text-default);
`;

const MailButton = styled.button`
  width: 14.5rem;
  align-self: center;
  background: var(--color-primary);
  color: var(--color-secondary);
  font-size: 1.4rem;
  padding: 1rem 0;
  margin: 3rem 0;
  text-decoration: none;
  border-radius: 3px;
  border: none;

  &:hover {
    cursor: pointer;
    background: var(--color-primary-dark);
  }
`;

const ContactSection = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  padding: 2rem 0;
  background: var(--text-default);
  color: var(--color-secondary);
`;

const ContactInfo = styled.h3`
  display: flex;
  align-items: center;
  min-width: 14vw;
  justify-content: space-between;
  margin: 1rem 1rem;
  font-size: 1.5rem;
  font-weight: 400;
`;
