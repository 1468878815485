import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import NavMenu from './NavMenu';
import YSLogo from "./YourStoneLogo.png";

const Navbar = () => {
  return (
    <NavbarWrapper>
      <NavbarContent>
        <Link to="/">
          <Logo src={YSLogo} />
        </Link>
        <NavMenu />
      </NavbarContent>
    </NavbarWrapper>
  );
};

export default Navbar;

const NavbarWrapper = styled.div`
  width: 100%;
  height: 3.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 10;
  box-shadow: 0 2px 20px 1px rgba(0, 0, 0, 0.2);
  background: var(--color-secondary);
`;

const NavbarContent = styled.div`
  width: 80%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: 768px) {
    width: 90%;
  }
`;

const Logo = styled.img`
  height: 2.5rem;

  @media screen and (max-width: 768px) {
    height: 1.7rem;
  }
`;
