import { createAction } from 'redux-actions';
import axios from 'axios';
import { HOME_URL } from '../lib/constants';

const getHomeRequest = createAction('GET_HOME_REQUEST');
const getHomeSuccess = createAction('GET_HOME_SUCCESS');
const getHomeFailure = createAction('GET_HOME_FAILURE');

const getHome = () => async dispatch => {
  try {
    dispatch(getHomeRequest());
    const response = await axios.get(HOME_URL);
    dispatch(getHomeSuccess(response.data.data));
  } catch { 
    dispatch(getHomeFailure());
  }
};

export default getHome;