import { createAction } from "redux-actions";
import axios from "axios";
import { GALLERY_URL } from "../lib/constants";

const getGalleryPostsRequest = createAction("GET_GALLERY_POSTS_REQUEST");
const getGalleryPostsSuccess = createAction("GET_GALLERY_POSTS_SUCCESS");
const getGalleryPostsFailure = createAction("GET_GALLERY_POSTS_FAILURE");

export const getGalleryPosts = (page) => async (dispatch) => {
  try {
    page = page || 1;
    dispatch(getGalleryPostsRequest());
    const response = await axios.get(`${GALLERY_URL}?page=${page}`);
    dispatch(getGalleryPostsSuccess(response.data));
  } catch (error) {
    dispatch(getGalleryPostsFailure());
  }
};
